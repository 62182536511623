define(['app', 'componentHelper'], function(app, componentHelper) {

  const productThirdPartyStoreFinderModal = function() {
    const component = {};

    const _config = {
      selectors: {
        productTPSSForm: '.productThirdPartyStoreFinderModal_form',
        productTPSSFormInput: 'productThirdPartyStoreFinderModal_search',
        productTPSSFormResults: 'productThirdPartyStoreFinderModal_results',
        productTPSSFormLoader: 'productThirdPartyStoreFinderModal_loader',
        productTPSSFormStockDisclaimer: 'productThirdPartyStoreFinderModal_stockDisclaimer',
        productTPSSFormError: 'productThirdPartyStoreFinderModal_error',
        productTPSSFormErrorRequiredFieldNotFilled: 'productThirdPartyStoreFinderModal_errorRequiredFieldNotFilled',
        productTPSSFormErrorNoResults: 'productThirdPartyStoreFinderModal_errorNoResults',
        tpssShopNowButton: '.shopNowButton',
      },
      subscribeChannels: {
        productInformation: 'productInformation/newInformation'
      }
    };

    const _init = (element) => {
      component.element = element;
      component.addEventListener();

      app.subscribe(component.config.subscribeChannels.productInformation, component.updateProductIdFromVariation);

      return component;
    };

    const _addEventListener = () => {
      const productTPSSForm = document.querySelector(component.config.selectors.productTPSSForm);

      if(productTPSSForm) {
        productTPSSForm.addEventListener('submit', component.getTPSSResults);
      }
    };

    const _updateProductIdFromVariation = (newProductId) => {
      if(newProductId !== undefined) {
        component.resetTPSSResults();
        component.element.setAttribute("data-product-id", newProductId);
      }
    };

    var _openShopNowUrl = function _openShopNowUrl(event) {
      let shopNowUrl = event.target.getAttribute("data-store-url");

      window.dataLayer.push({
        event: "elysiumEvent",
        eventData: {
          eventCategory: "Shop Now",
          eventAction: "Clicked",
          eventLabel: shopNowUrl,
          eventLabelValue: "",
          eventPage: window.location.pathname
        }
      });

      window.open(shopNowUrl,'_blank');
    };

    const _getTPSSResults = (event) => {
      event.preventDefault();
      component.resetTPSSResults();
      document.getElementById(component.config.selectors.productTPSSFormLoader).style.display = 'block';

      let postcode = document.getElementById(component.config.selectors.productTPSSFormInput).value;
      let productId = component.element.getAttribute("data-product-id");

      if(postcode === '') {
        component.displayTPSSErrorMessage(component.config.selectors.productTPSSFormErrorRequiredFieldNotFilled);
      } else {
        app.ajax.get({
          url: `${window.location.origin}/nearestThirdPartyStores.lookup?productId=${productId}&postcode=${postcode}`,
          timeout: 10000,
          success: (response) => {
            let htmlResponse = new DOMParser().parseFromString(response,"text/html");
            if(htmlResponse
              && htmlResponse.body
              && htmlResponse.body.childNodes[0]
              && htmlResponse.body.childNodes[0].children
              && htmlResponse.body.childNodes[0].children.length === 0) {
              component.displayTPSSErrorMessage(component.config.selectors.productTPSSFormErrorNoResults);
            } else {
              document.getElementById(component.config.selectors.productTPSSFormResults).innerHTML = response;
              document.querySelectorAll(component.config.selectors.tpssShopNowButton).forEach(function (button) {
                button.removeEventListener('click', component.openShopNowUrl)
                button.addEventListener('click', component.openShopNowUrl)
              });
            }
            document.getElementById(component.config.selectors.productTPSSFormLoader).style.display = 'none';
          },
          error: () => {
            component.displayTPSSErrorMessage(component.config.selectors.productTPSSFormErrorNoResults);
          }
        });
      }
    };

    const _resetTPSSResults = () => {
      document.getElementById(component.config.selectors.productTPSSFormResults).innerHTML = '';
      document.getElementById(component.config.selectors.productTPSSFormStockDisclaimer).style.display = 'flex';
      document.getElementById(component.config.selectors.productTPSSFormError).style.display = 'none';
      document.getElementById(component.config.selectors.productTPSSFormErrorRequiredFieldNotFilled).style.display = 'none';
      document.getElementById(component.config.selectors.productTPSSFormErrorNoResults).style.display = 'none';
    }

    const _displayTPSSErrorMessage = (messageSelector) => {
      document.getElementById(component.config.selectors.productTPSSFormLoader).style.display = 'none';
      document.getElementById(component.config.selectors.productTPSSFormError).style.display = 'flex';
      document.getElementById(component.config.selectors.productTPSSFormStockDisclaimer).style.display = 'none';
      document.getElementById(messageSelector).style.display = 'block';
    }

    component.config = _config;
    component.init = _init;
    component.addEventListener = _addEventListener;
    component.updateProductIdFromVariation = _updateProductIdFromVariation;
    component.getTPSSResults = _getTPSSResults;
    component.resetTPSSResults = _resetTPSSResults;
    component.displayTPSSErrorMessage = _displayTPSSErrorMessage;
    component.openShopNowUrl = _openShopNowUrl;
    return component;
  };

  return productThirdPartyStoreFinderModal;
});
